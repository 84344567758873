import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';

const DateRangeSelector = ({ startDate, endDate, onStartDateChange, onEndDateChange, onDateChange }) => {
  const debouncedDateChange = useMemo(
    () => debounce((start, end) => onDateChange(start, end), 100),
    [onDateChange]
  );

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    onStartDateChange(newStartDate);
    if (endDate && newStartDate) {
      debouncedDateChange(newStartDate, endDate);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    onEndDateChange(newEndDate);
    if (startDate && newEndDate) {
      debouncedDateChange(startDate, newEndDate);
    }
  };

  return (
    <div>
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
      />
      <input
        type="date"
        value={endDate}
        onChange={handleEndDateChange}
      />
    </div>
  );
};

export default DateRangeSelector;