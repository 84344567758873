import React, { useState, useEffect } from 'react';
import { Box, Tooltip } from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002';

const DatabaseStatus = () => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/db-status`, {
          credentials: 'include'
        });
        setIsConnected(response.ok);
      } catch (error) {
        setIsConnected(false);
      }
    };

    checkConnection();
    const interval = setInterval(checkConnection, 30000); // Check every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Tooltip title={`Database ${isConnected ? 'Connected' : 'Disconnected'}`}>
      <Box
        sx={{
          width: 12,
          height: 12,
          borderRadius: '50%',
          backgroundColor: isConnected ? '#4caf50' : '#f44336',
          marginRight: 2,
          transition: 'background-color 0.3s ease'
        }}
      />
    </Tooltip>
  );
};

export default DatabaseStatus;