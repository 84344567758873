import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Box,
  styled,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import CategoryIcon from '@mui/icons-material/Category';
import FavoriteIcon from '@mui/icons-material/Favorite';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: theme.spacing(2),
    width: '100%',
    maxWidth: '600px',
    borderRadius: '16px',
    overflow: 'hidden'
  }
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 'none'
  }
}));

const WelcomeModal = ({ open, onClose, onStartExploring }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStartExploring = () => {
    onStartExploring();
    onClose(dontShowAgain);
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 2 }}>
        <Typography variant="h5" component="div">
          Welcome to Cedar Rapids Area Auctions
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {/* Primary Message */}
        <Box sx={{ 
          p: 3, 
          textAlign: 'center',
          bgcolor: 'primary.light',
          color: 'primary.contrastText'
        }}>
          <Button
            onClick={handleStartExploring}
            variant="contained"
            startIcon={<SearchIcon />}
            sx={{ 
              py: 1,
              px: 3,
              fontSize: '1rem',
              borderRadius: '8px',
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.dark',
                color: 'white',
              }
            }}
          >
            Start Exploring
          </Button>
        </Box>

        {/* Main Features List */}
        <Typography variant="subtitle1" sx={{ p: 2, color: 'text.secondary' }}>
          Available features:
        </Typography>
        <Box>
          <FeatureItem>
            <MapIcon color="primary" sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="h6">Interactive Map</Typography>
              <Typography variant="body2" color="text.secondary">
                View auctions within 250 miles of Cedar Rapids from various local auctioneers
              </Typography>
            </Box>
          </FeatureItem>

          <FeatureItem>
            <SearchIcon color="primary" sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="h6">Smart Search</Typography>
              <Typography variant="body2" color="text.secondary">
                Find specific items across all auctions.  Setup an alert for an item and get notified when it's posted.
              </Typography>
            </Box>
          </FeatureItem>

          <FeatureItem>
            <CategoryIcon color="primary" sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="h6">Categories</Typography>
              <Typography variant="body2" color="text.secondary">
                Browse items by type, auctioneer, and location
              </Typography>
            </Box>
          </FeatureItem>

          <FeatureItem>
            <FavoriteIcon color="primary" sx={{ fontSize: 32 }} />
            <Box>
              <Typography variant="h6">Favorites</Typography>
              <Typography variant="body2" color="text.secondary">
                Save and track items you're interested in for future research
              </Typography>
            </Box>
          </FeatureItem>
        </Box>

        {/* Don't Show Again Checkbox */}
        <Box sx={{ p: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={(e) => setDontShowAgain(e.target.checked)}
              />
            }
            label="Hide this shit, I know how to use a map"
          />
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default WelcomeModal;