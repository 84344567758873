import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { 
  ThemeProvider, 
  createTheme, 
  Box, 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Container, 
  Paper, 
  CssBaseline,
  Snackbar,
  Alert,
  IconButton
} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MapComponent from './components/MapComponent';
import EndpointSelector from './components/EndpointSelector';
import DateRangeSelector from './components/DateRangeSelector';
import { exportCSV } from './components/dataExport';
import { tableNames, getCategoryFromUrl } from './components/utils';
import { categorizeItems } from './components/categorizeItems';
import CategorizedCounts from './components/CategorizedCounts';
import CategoryDetails from './components/CategoryDetails';
import SearchBox from './components/SearchBox';
import DataLoadingProgress from './components/DataLoadingProgress';
import AlertManager from './components/AlertManager';
import UnifiedSidebar from './components/UnifiedSidebar';
import debounce from 'lodash/debounce';
import WelcomeModal from './components/WelcomeModal';
import DatabaseStatus from './components/DatabaseStatus';
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '@mui/icons-material/Category';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';
import styled from '@emotion/styled';
import mapboxgl from 'mapbox-gl';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3002';

const fetchConfig = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

const createGradientTheme = (mode) => {
  const baseTheme = createTheme({
    palette: {
      mode,
      primary: {
        main: '#006B3E',
        light: '#4CAF50',
      },
      secondary: {
        main: '#87CEEB',
        light: '#B0E0E6',
      },
    },
  });

  return createTheme(baseTheme, {
    gradients: {
      primary: 'linear-gradient(45deg, #006B3E 30%, #4CAF50 90%)',
      secondary: 'linear-gradient(45deg, #87CEEB 30%, #B0E0E6 90%)',
      success: 'linear-gradient(45deg, #4CAF50 30%, #81C784 90%)',
      warning: 'linear-gradient(45deg, #FFA726 30%, #FFB74D 90%)',
      error: 'linear-gradient(45deg, #E57373 30%, #EF9A9A 90%)',
      dark: mode === 'dark' 
        ? 'linear-gradient(45deg, #1B4D3E 30%, #2E7D32 90%)'
        : 'linear-gradient(45deg, #FFFFFF 30%, #F5F5F5 90%)',
      background: mode === 'dark'
        ? 'linear-gradient(45deg, #1B4D3E 30%, #2E7D32 90%)'
        : 'linear-gradient(45deg, #FFFFFF 30%, #F8F8F8 90%)',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundImage: 'linear-gradient(45deg, #006B3E 30%, #4CAF50 90%)',
            border: 0,
            borderRadius: 8,
            boxShadow: '0 3px 5px 2px rgba(0, 107, 62, .3)',
            color: 'white',
            height: 32,
            padding: '0 12px',
            '&:hover': {
              backgroundImage: 'linear-gradient(45deg, #005A34 50%, #43A047 100%)',
              boxShadow: '0 4px 6px 2px rgba(0, 107, 62, .4)',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundImage: 'linear-gradient(45deg, #006B3E 30%, #4CAF50 90%)',
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: mode === 'dark'
              ? 'linear-gradient(45deg, #1B4D3E 30%, #2E7D32 90%)'
              : 'linear-gradient(45deg, #FFFFFF 30%, #F8F8F8 90%)',
            borderRadius: 12,
          }
        }
      }
    }
  });
};

const darkTheme = createGradientTheme('dark');
const lightTheme = createGradientTheme('light');

const StyledButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4],
  },
  '&:active': {
    transform: 'scale(0.95)',
  }
}));

const US_BOUNDS = {
  west: -125.0,  // West coast
  south: 24.396308,  // Southern tip of Florida
  east: -66.93457,  // East coast
  north: 49.384358,  // Northern border
};

const App = () => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const rawDataRef = useRef([]);
  const [categorizedData, setCategorizedData] = useState({});
  const [selectedEndpoint, setSelectedEndpoint] = useState('all_tables');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategories, setShowCategories] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [mapData, setMapData] = useState([]);
  const isLoadingRef = useRef(false);
  const [clusterData, setClusterData] = useState([]);
  const [showWelcome, setShowWelcome] = useState(() => {
    return localStorage.getItem('hideWelcome') !== 'true';
  });
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(() => {
    const audioElement = new Audio();
    audioElement.src = process.env.PUBLIC_URL + '/tetris.mp3';
    audioElement.preload = 'auto';
    return audioElement;
  });
  const [filteredMapData, setFilteredMapData] = useState([]);
  const mapComponentRef = useRef(null);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    // Suppress WebGL warnings
    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (args[0]?.includes?.('WebGL') && args[0]?.includes?.('texImage')) {
        return; // Suppress specific WebGL warnings
      }
      originalWarn.apply(console, args);
    };

    return () => {
      console.warn = originalWarn; // Restore original warning function
    };
  }, []);

  useEffect(() => {
    const loadAudio = async () => {
      try {
        await audio.load();
      } catch (err) {
        console.error('Failed to load audio:', err);
      }
    };
    loadAudio();
  }, [audio]);

  const toggleMusic = useCallback(() => {
    try {
      if (isPlaying) {
        audio.pause();
        audio.currentTime = 0;
      } else {
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              console.log('Audio started playing successfully');
            })
            .catch(err => {
              console.log('Audio playback failed:', err);
              setIsPlaying(false);
            });
        }
      }
      setIsPlaying(!isPlaying);
    } catch (err) {
      console.error('Toggle music error:', err);
      setIsPlaying(false);
    }
  }, [isPlaying, audio]);

  useEffect(() => {
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [audio]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleCategorization = useCallback((data) => {
    if (!Array.isArray(data) || data.length === 0) {
      console.log("No data to categorize in handleCategorization");
      return {};
    }

    const categorizedResults = categorizeItems(data);
    const formattedResults = {};
    
    categorizedResults.forEach(item => {
      if (!formattedResults[item.category]) {
        formattedResults[item.category] = [];
      }
      formattedResults[item.category].push(item);
    });
    
    console.log("Formatted results in handleCategorization:", formattedResults);
    return formattedResults;
  }, []);

  const handleClusterClick = useCallback((features) => {
    setShowCategories(true);
    setClusterData(features.map(f => f.get ? f.get('itemData') : f.itemData || f));
  }, []);

  const handleCategorize = () => {
    if (!mapData || mapData.length === 0) {
      console.log("No data to categorize");
      return;
    }
    
    const categorizedItems = categorizeItems(mapData);
    setMapData(categorizedItems);
    setShowCategories(true);
  };

  const handleCloseCategorization = useCallback(() => {
    setShowCategories(false);
    setSelectedCategory(null);
  }, []);

  const handleBackToCategories = useCallback(() => {
    setSelectedCategory(null);
  }, []);

  const handleRowSelect = useCallback((rowUrl) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowUrl)) {
        return prevSelectedRows.filter((url) => url !== rowUrl);
      } else {
        return [...prevSelectedRows, rowUrl];
      }
    });
  }, []);
  const loadAuctionData = useCallback(async () => {
    if (isLoadingRef.current) {
      console.log('Already loading data...');
      return;
    }
    
    if (!selectedEndpoint) {
      console.log('No endpoint selected');
      return;
    }

    isLoadingRef.current = true;
    setIsLoading(true);
    setLoadingProgress(0);
    setLoadingStatus('Initializing data load...');
  
    try {
      let url;
      if (selectedEndpoint === 'all_tables') {
        url = `${apiUrl}/api/get-auction-data?tableName=all_tables&startDate=${startDate}&endDate=${endDate}`;
      } else {
        url = `${apiUrl}/api/get-auction-data?tableName=${selectedEndpoint}&startDate=${startDate}&endDate=${endDate}`;
      }
  
      console.log('Fetching data from URL:', url);
  
      setLoadingStatus('Sending request to server...');
      
      const response = await fetch(url, {
        ...fetchConfig,
        method: 'GET'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      setLoadingStatus('Receiving data from server...');
      
      const jsonData = await response.json();
  
      if (process.env.NODE_ENV !== 'production') {
        console.log('Data loaded:', jsonData);
        console.log('Raw data:', rawDataRef.current);
      }
  
      if (Array.isArray(jsonData)) {
        rawDataRef.current = jsonData;
        setMapData(jsonData);  // Ensure mapData is updated
        setError(null);
      } else if (jsonData.error) {
        throw new Error(jsonData.error);
      } else {
        throw new Error('Unexpected data format received from server');
      }
  
      setLoadingStatus('Processing received data...');
      setUpdateTrigger((prev) => prev + 1);
      setLoadingStatus('Data load complete');
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setError(error.message);
      setLoadingStatus('Error: ' + error.message);
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
      setLoadingProgress(100);
    }
  }, [selectedEndpoint, startDate, endDate]);

  const loadAuctionDataRef = useRef(loadAuctionData);
  
  useEffect(() => {
    loadAuctionDataRef.current = loadAuctionData;
  }, [loadAuctionData]);

  const debouncedLoadAuctionData = useMemo(
    () => debounce(loadAuctionData, 300),
    [loadAuctionData]
  );

  useEffect(() => {
    return () => {
      debouncedLoadAuctionData.cancel();
    };
  }, [debouncedLoadAuctionData]);

  const loadDefaultData = useCallback(() => {
    // Clear all data states
    rawDataRef.current = [];
    setMapData([]);
    setFilteredMapData([]);
    setCategorizedData({});
    setSelectedRows([]);
    setSelectedCategory(null);
    setShowCategories(false);
    setError(null);
    setClusterData([]);
    
    // Update to use current date
    const currentDate = new Date().toISOString().split('T')[0];
    setStartDate(currentDate);
    setEndDate(currentDate);
    
    // Reset endpoint selector
    setSelectedEndpoint('all_tables');
    
    // Clear loading states
    setIsLoading(false);
    setLoadingProgress(0);
    setLoadingStatus('');
    
    // Update map data through the map source
    if (mapComponentRef.current) {
      const map = mapComponentRef.current.getMap();
      if (map) {
        const source = map.getSource('auctions');
        if (source) {
          source.setData({
            type: 'FeatureCollection',
            features: []
          });
        }
      }
    }
    
    // Trigger map update
    setUpdateTrigger(prev => prev + 1);
  }, []);

  const handleExportClick = useCallback(() => {
    try {
      const dataToExport = selectedRows.length > 0
        ? rawDataRef.current.filter(item => selectedRows.includes(item.url))
        : rawDataRef.current;

      console.log('Exporting data:', dataToExport.length, 'items');
      exportCSV(dataToExport, 'auction_data');
    } catch (error) {
      console.error('Export failed:', error);
      setError('Export failed: ' + error.message);
    }
  }, [selectedRows]);

  const handleFavorite = useCallback(async (item) => {
    console.log('Toggling favorite status for item:', item);
    const newFavoriteStatus = item.favorite === 'Y' ? 'N' : 'Y';

    let tableName = item.original_table || item.table_name;
    if (!tableName) {
      const category = getCategoryFromUrl(item.url);
      tableName = tableNames[category] || category;
    }

    if (tableName === 'wiscosurp') {
      tableName = 'wiscosurp_auctions';
    }

    console.log('Using table name:', tableName);

    if (!tableName || !item.url) {
      console.error('Missing table name or URL');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/favorite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          favorite: newFavoriteStatus,
          tableName: tableName,
          lot_number: item.lot_number || '',
          id: item.id || '',
          url: item.url,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || `HTTP error! status: ${response.status}`);
      }

      if (result.success) {
        console.log('Favorite status updated successfully');
        const updatedData = rawDataRef.current.map(d =>
          d.url === item.url ? { ...d, favorite: newFavoriteStatus } : d
        );
        rawDataRef.current = updatedData;
        setMapData(updatedData);
        setUpdateTrigger(prev => prev + 1);
      } else {
        throw new Error(result.error || 'Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
      setError('Failed to update favorite status: ' + error.message);
    }
  }, []);

  const handleSearch = useCallback(async (searchQuery) => {
    console.log('handleSearch called with query:', searchQuery);

    if (!selectedEndpoint) {
      setError('Please select an endpoint before searching.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const currentDate = new Date();
      const startDate = currentDate.toISOString().split('T')[0];
      const endDate = new Date(currentDate.setDate(currentDate.getDate() + 90)).toISOString().split('T')[0];

      const params = new URLSearchParams({
        tableName: selectedEndpoint,
        startDate: startDate,
        endDate: endDate,
        searchTerm: searchQuery
      });

      const url = `${apiUrl}/api/get-auction-data?${params.toString()}`;

      console.log('Fetching search results from URL:', url);

      const response = await fetch(url);

      if (response.status === 404) {
        console.log(`No items found for "${searchQuery}" in the next 90 days.`);
        rawDataRef.current = [];
        setMapData([]);
        setError(`No items found for "${searchQuery}" in the next 90 days.`);
      } else if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        const result = await response.json();
        console.log('Search results loaded:', result);
        rawDataRef.current = result;
        setMapData(result);
      }
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error('Failed to fetch search results:', error);
      setError(`Error: ${error.message}`);
      rawDataRef.current = [];
      setMapData([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedEndpoint]); // Removed apiUrl from the dependency array

  const loadFavoritesData = useCallback(async () => {
    console.log('Loading favorites data');
    setIsLoading(true);
    setLoadingStatus('Loading favorites...');
    try {
      const response = await fetch(`${apiUrl}/api/favorites`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const favoritesData = await response.json();
      console.log('Favorites data loaded:', favoritesData);
      rawDataRef.current = favoritesData;
      setMapData(favoritesData);
      handleCategorization(favoritesData);
      setUpdateTrigger(prev => prev + 1);
      setLoadingStatus('Favorites loaded successfully');
    } catch (err) {
      console.error('Failed to fetch favorites data:', err.message);
      setError('Failed to load favorites: ' + err.message);
      setLoadingStatus('Error loading favorites');
    } finally {
      setIsLoading(false);
    }
    
  }, [handleCategorization]); // Removed apiUrl from the dependency array
  useEffect(() => {
    console.log('Current state:', { selectedEndpoint, startDate, endDate });
  }, [selectedEndpoint, startDate, endDate]);

  const isGetDataDisabled = !selectedEndpoint || !startDate || !endDate;

  const handleCategoryClick = useCallback((category) => {
    const filteredItems = categorizedData[category] || [];
    
    setMapData(filteredItems);
    
    setShowCategories(false);
    
    setSelectedCategory(category);
    
    setClusterData(filteredItems);
  }, [categorizedData]);

  const handleAlertDismiss = useCallback((alertId) => {
    setAlerts(prev => prev.filter(alert => alert.id !== alertId));
  }, []);

  const handleAlertClick = useCallback(async (filteredData) => {
    if (!Array.isArray(filteredData)) {
      console.warn('Invalid data received:', filteredData);
      return;
    }
    
    // Update both the raw data and map data
    rawDataRef.current = filteredData;
    setMapData(filteredData);
    setFilteredMapData(filteredData);
    setUpdateTrigger(prev => prev + 1);
    
    // Close any open categories
    setShowCategories(false);
    setSelectedCategory(null);
    
    // If we have valid coordinates, fit the map to show all results
    if (filteredData.length > 0 && mapComponentRef.current) {
      const map = mapComponentRef.current.getMap();
      if (map) {
        // Create bounds object to contain all points
        const bounds = new mapboxgl.LngLatBounds([US_BOUNDS.west, US_BOUNDS.south, US_BOUNDS.east, US_BOUNDS.north]);
        
        // Extend bounds to include each point, but only if they're within the US
        filteredData.forEach(point => {
          if (point.longitude && point.latitude) {
            const lng = parseFloat(point.longitude);
            const lat = parseFloat(point.latitude);
            
            // Only include points within continental US bounds
            if (lng >= US_BOUNDS.west && lng <= US_BOUNDS.east && 
                lat >= US_BOUNDS.south && lat <= US_BOUNDS.north) {
              bounds.extend([lng, lat]);
            }
          }
        });
        
        // Fit map to bounds with padding and zoom constraints
        map.fitBounds(bounds, {
          padding: 50,
          maxZoom: 8,
          duration: 1000,
          essential: true
        });
      }
    }
  }, []);

  const handleDateChange = useCallback(async (newStartDate, newEndDate) => {
    if (!selectedEndpoint || !newStartDate || !newEndDate) return;
    
    // Prevent loading if dates haven't actually changed
    if (newStartDate === startDate && newEndDate === endDate) return;
    
    // Cancel any pending debounced calls
    debouncedLoadAuctionData.cancel();
    
    // Update both dates at once to prevent multiple renders
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    
    // Trigger the debounced load
    debouncedLoadAuctionData();
    
  }, [selectedEndpoint, startDate, endDate, debouncedLoadAuctionData]);

  const handleCloseWelcome = useCallback((dontShowAgain) => {
    setShowWelcome(false);
    if (dontShowAgain) {
      localStorage.setItem('hideWelcome', 'true');
    }
  }, []);

  const handleStartExploring = useCallback(() => {
    // Set a default endpoint if none is selected
    if (!selectedEndpoint) {
      setSelectedEndpoint('all_tables');
    }
    // Load the auction data
    debouncedLoadAuctionData();
  }, [selectedEndpoint, debouncedLoadAuctionData]);

  const handleCategorySelect = useCallback((category, items) => {
    if (category && items) {
      // Filter map data to show only items from selected category
      setFilteredMapData(items);
    } else {
      // Reset to show all items
      setFilteredMapData(mapData);
    }
  }, [mapData]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Typography 
              variant="h6" 
              component="h1"
              sx={{ 
                textAlign: 'center',
                flexGrow: 1,
                fontWeight: 'bold',
                letterSpacing: '0.5px'
              }}
            >
              Cedar Rapids Area Auctions
            </Typography>
            <IconButton 
              onClick={toggleDarkMode} 
              color="inherit"
              sx={{ ml: 1 }}
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <IconButton
              onClick={toggleMusic}
              color="inherit"
              sx={{ ml: 1 }}
            >
              {isPlaying ? <MusicOffIcon /> : <MusicNoteIcon />}
            </IconButton>
            <DatabaseStatus />
          </Toolbar>
        </AppBar>
        <Container maxWidth={false} sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          py: 2,
          position: 'relative',
          zIndex: 1,
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            alignItems: 'center', 
            gap: 0.5,
            mb: 1,
            '& > *': { 
              minWidth: 'auto',
              flexShrink: 0 
            }
          }}>
            <EndpointSelector selectedEndpoint={selectedEndpoint} onEndpointChange={setSelectedEndpoint} />
            <DateRangeSelector 
              startDate={startDate} 
              endDate={endDate} 
              onStartDateChange={setStartDate} 
              onEndDateChange={setEndDate} 
              onDateChange={handleDateChange} 
            />
            <StyledButton 
              variant="contained" 
              onClick={debouncedLoadAuctionData} 
              disabled={isGetDataDisabled} 
              size="small"
              startIcon={<SearchIcon />}
            >
              Get Data
            </StyledButton>
            <StyledButton 
              variant="contained" 
              onClick={handleCategorize} 
              size="small"
              startIcon={<CategoryIcon />}
            >
              Categorize
            </StyledButton>
            <StyledButton 
              variant="contained" 
              onClick={loadFavoritesData} 
              size="small"
              startIcon={<FavoriteIcon />}
            >
              Favorites
            </StyledButton>
            {showCategories && (
              <Button 
                variant="contained" 
                onClick={handleCloseCategorization} 
                size="small"
                startIcon={<CloseIcon />}
              >
                Close Categories
              </Button>
            )}
            <Button 
              variant="contained" 
              onClick={loadDefaultData} 
              size="small"
              startIcon={<ClearIcon />}
            >
              Clear
            </Button>
            <Box sx={{ flexGrow: 1, minWidth: '200px' }}>
              <SearchBox onSearch={handleSearch} />
            </Box>
          </Box>
          <DataLoadingProgress isLoading={isLoading} progress={loadingProgress} status={loadingStatus} />
          <Box sx={{ flexGrow: 1, display: 'flex', minHeight: 0 }}>
            <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', mr: showCategories ? 2 : 0 }}>
              <MapComponent 
                ref={mapComponentRef}
                data={mapData}
                filteredData={filteredMapData}
                selectedEndpoint={selectedEndpoint}
                onClusterClick={handleClusterClick}
                onRowSelect={handleRowSelect}
                selectedRows={selectedRows}
                handleFavorite={handleFavorite}
                updateTrigger={updateTrigger}
                darkMode={darkMode}
                handleExportClick={handleExportClick}
              />
            </Paper>
            {showCategories && (
              <Paper elevation={3} sx={{ width: 300, p: 2, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                {selectedCategory ? (
                  <>
                    <Button variant="outlined" onClick={handleBackToCategories} sx={{ mb: 2 }}>Back to Categories</Button>
                    <CategoryDetails category={selectedCategory} items={categorizedData[selectedCategory]} onFavorite={handleFavorite} onRowClick={handleRowSelect} selectedRows={selectedRows} />
                  </>
                ) : (
                  <CategorizedCounts categorizedData={categorizedData} onCategoryClick={handleCategoryClick} />
                )}
              </Paper>
            )}
          </Box>
        </Container>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>{error}</Alert>
        </Snackbar>
        {showCategories && (
          <UnifiedSidebar
            data={clusterData || mapData}
            isClusterView={!!clusterData}
            onClose={() => {
              setShowCategories(false);
              setClusterData(null);
            }}
            onFavorite={handleFavorite}
            onRowSelect={handleRowSelect}
            selectedRows={selectedRows}
            onCategorySelect={handleCategorySelect}
            darkMode={darkMode}
            onExport={(data) => handleExportClick(data)}
            onSearch={(category) => {
              handleSearch(category);
              setShowCategories(false);
            }}
          />
        )}
        {showWelcome && (
          <WelcomeModal
            open={showWelcome}
            onClose={handleCloseWelcome}
            onStartExploring={handleStartExploring}
          />
        )}
        <AlertManager 
          onAlertClick={handleAlertClick}
          alerts={alerts}
          onAlertDismiss={handleAlertDismiss}
          darkMode={darkMode}
        />
      </Box>
    </ThemeProvider>
  );
}

export default App;
