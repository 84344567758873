import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const EndpointSelector = ({ selectedEndpoint, onEndpointChange }) => {
  const endpoints = [
    { name: 'All Tables', value: 'all_tables' },
    { name: 'Ending Today', value: 'ending_today' },
    { name: 'Backes', value: 'backes' },
    { name: 'Bidspotter', value: 'bidspotter' },
    { name: 'GovDeals', value: 'govdeals' },
    { name: 'GSA', value: 'gsa' },
    { name: 'Public Surplus', value: 'publicsurplus' },
    { name: 'SMC', value: 'smc' },
    { name: 'Proxibid', value: 'proxibid' },
    { name: 'Wisco Surplus', value: 'wiscosurp_auctions' },
    { name: 'HiBid', value: 'hibid' }
  ];

  return (
    <FormControl 
      variant="outlined" 
      size="small" 
      sx={{ 
        width: '150px', 
        marginRight: '8px', 
        '& .MuiInputLabel-root': {
          fontSize: '0.675rem',
          transform: 'translate(8px, 6px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(8px, -9px) scale(0.75)',
          },
        },
        '& .MuiMenuItem-root': {
          fontSize: '0.675rem',
          minHeight: '24px',
          padding: '4px 8px',
        },
        '& .MuiSelect-select': {
          padding: '0 24px 0 8px',
          height: '24px',
          minHeight: '24px !important',
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <InputLabel>Endpoint</InputLabel>
      <Select
        value={selectedEndpoint}
        onChange={(event) => onEndpointChange(event.target.value)}
        label="Endpoint"
      >
        {endpoints.map((endpoint) => (
          <MenuItem key={endpoint.value} value={endpoint.value}>
            {endpoint.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EndpointSelector;