import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import UnifiedSidebar from './UnifiedSidebar';
import { getCategoryFromUrl } from './utils';
import { exportCSV } from './dataExport';
import 'mapbox-gl/dist/mapbox-gl.css';

const sourceColors = {
  govdeals: '#ff0000',      // red
  wiscosurp: '#0000ff',     // blue
  hibid: '#00ff00',         // green
  publicsurplus: '#ffa500', // orange
  gsa: '#800080',          // purple
  backes: '#00ffff',       // cyan
  bidspotter: '#ff00ff',   // magenta
  smc: '#a52a2a',          // brown
  heartland: '#ffff00',    // yellow
  proxibid: '#808080',     // gray
  default: '#51bbd6'       // default blue
};

// Improve token validation
if (!process.env.REACT_APP_MAPBOX_TOKEN) {
  throw new Error('Mapbox token not found. Please ensure REACT_APP_MAPBOX_TOKEN is set in your environment variables.');
}

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

// Add these constants at the top of the file
const CEDAR_RAPIDS_COORDS = {
  lng: -91.6656,  // Cedar Rapids longitude
  lat: 41.9779    // Cedar Rapids latitude
};

// Calculate the zoom level for ~250 mile radius
// At 40° latitude, each degree is approximately 69 miles
// 250 miles = ~3.6 degrees
// Zoom level formula: log2(360 * width / (distance * 256))
const DEFAULT_ZOOM = 6;  // This zoom level covers roughly 250 miles at this latitude

// Update the US_BOUNDS constant at the top of the file
const US_BOUNDS = {
  west: -125.0,  // West coast
  south: 24.396308,  // Southern tip of Florida
  east: -66.93457,  // East coast
  north: 49.384358   // Northern border
};

const MapComponent = React.forwardRef(({ 
  data, 
  selectedEndpoint, 
  onClusterClick, 
  onRowSelect, 
  selectedRows, 
  handleFavorite, 
  updateTrigger, 
  darkMode, 
  center = CEDAR_RAPIDS_COORDS,
  zoom = DEFAULT_ZOOM,
  handleExportClick,
  filteredData
}, ref) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [clusterData, setClusterData] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);

  const setupMapLayers = useCallback(() => {
    const map = mapRef.current;
    if (!map.getSource('auctions')) {
      map.addSource('auctions', {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [] },
        cluster: true,
        clusterMaxZoom: 10,
        clusterRadius: 30,
        clusterProperties: {
          'govdeals_count': ['+', ['case', ['==', ['get', 'table_name'], 'govdeals'], 1, 0]],
          'wiscosurp_count': ['+', ['case', ['==', ['get', 'table_name'], 'wiscosurp'], 1, 0]],
          'hibid_count': ['+', ['case', ['==', ['get', 'table_name'], 'hibid'], 1, 0]],
          'publicsurplus_count': ['+', ['case', ['==', ['get', 'table_name'], 'publicsurplus'], 1, 0]],
          'gsa_count': ['+', ['case', ['==', ['get', 'table_name'], 'gsa'], 1, 0]],
          'backes_count': ['+', ['case', ['==', ['get', 'table_name'], 'backes'], 1, 0]],
          'bidspotter_count': ['+', ['case', ['==', ['get', 'table_name'], 'bidspotter'], 1, 0]],
          'smc_count': ['+', ['case', ['==', ['get', 'table_name'], 'smc'], 1, 0]],
          'heartland_count': ['+', ['case', ['==', ['get', 'table_name'], 'heartland'], 1, 0]],
          'proxibid_count': ['+', ['case', ['==', ['get', 'table_name'], 'proxibid'], 1, 0]]
        }
      });

      // Cluster layer with complete color logic
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'auctions',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'case',
            ['all',
              ['>', ['get', 'govdeals_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'hibid_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'gsa_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'backes_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'smc_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'heartland_count']],
              ['>', ['get', 'govdeals_count'], ['get', 'proxibid_count']]
            ], sourceColors.govdeals,
            ['all',
              ['>', ['get', 'wiscosurp_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'hibid_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'gsa_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'backes_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'smc_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'heartland_count']],
              ['>', ['get', 'wiscosurp_count'], ['get', 'proxibid_count']]
            ], sourceColors.wiscosurp,
            ['all',
              ['>', ['get', 'hibid_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'hibid_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'hibid_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'hibid_count'], ['get', 'gsa_count']],
              ['>', ['get', 'hibid_count'], ['get', 'backes_count']],
              ['>', ['get', 'hibid_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'hibid_count'], ['get', 'smc_count']],
              ['>', ['get', 'hibid_count'], ['get', 'heartland_count']],
              ['>', ['get', 'hibid_count'], ['get', 'proxibid_count']]
            ], sourceColors.hibid,
            ['all',
              ['>', ['get', 'publicsurplus_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'hibid_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'gsa_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'backes_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'smc_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'heartland_count']],
              ['>', ['get', 'publicsurplus_count'], ['get', 'proxibid_count']]
            ], sourceColors.publicsurplus,
            ['all',
              ['>', ['get', 'gsa_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'gsa_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'gsa_count'], ['get', 'hibid_count']],
              ['>', ['get', 'gsa_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'gsa_count'], ['get', 'backes_count']],
              ['>', ['get', 'gsa_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'gsa_count'], ['get', 'smc_count']],
              ['>', ['get', 'gsa_count'], ['get', 'heartland_count']],
              ['>', ['get', 'gsa_count'], ['get', 'proxibid_count']]
            ], sourceColors.gsa,
            ['all',
              ['>', ['get', 'backes_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'backes_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'backes_count'], ['get', 'hibid_count']],
              ['>', ['get', 'backes_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'backes_count'], ['get', 'gsa_count']],
              ['>', ['get', 'backes_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'backes_count'], ['get', 'smc_count']],
              ['>', ['get', 'backes_count'], ['get', 'heartland_count']],
              ['>', ['get', 'backes_count'], ['get', 'proxibid_count']]
            ], sourceColors.backes,
            ['all',
              ['>', ['get', 'bidspotter_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'hibid_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'gsa_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'backes_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'smc_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'heartland_count']],
              ['>', ['get', 'bidspotter_count'], ['get', 'proxibid_count']]
            ], sourceColors.bidspotter,
            ['all',
              ['>', ['get', 'smc_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'smc_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'smc_count'], ['get', 'hibid_count']],
              ['>', ['get', 'smc_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'smc_count'], ['get', 'gsa_count']],
              ['>', ['get', 'smc_count'], ['get', 'backes_count']],
              ['>', ['get', 'smc_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'smc_count'], ['get', 'heartland_count']],
              ['>', ['get', 'smc_count'], ['get', 'proxibid_count']]
            ], sourceColors.smc,
            ['all',
              ['>', ['get', 'heartland_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'heartland_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'heartland_count'], ['get', 'hibid_count']],
              ['>', ['get', 'heartland_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'heartland_count'], ['get', 'gsa_count']],
              ['>', ['get', 'heartland_count'], ['get', 'backes_count']],
              ['>', ['get', 'heartland_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'heartland_count'], ['get', 'smc_count']],
              ['>', ['get', 'heartland_count'], ['get', 'proxibid_count']]
            ], sourceColors.heartland,
            ['all',
              ['>', ['get', 'proxibid_count'], ['get', 'govdeals_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'wiscosurp_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'hibid_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'publicsurplus_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'gsa_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'backes_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'bidspotter_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'smc_count']],
              ['>', ['get', 'proxibid_count'], ['get', 'heartland_count']]
            ], sourceColors.proxibid,
            sourceColors.default
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,   // Default radius
            100,  // Points needed for next step
            30,   // Next radius
            750,  // Points needed for next step
            40    // Max radius
          ]
        }
      });

      // Cluster count layer
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'auctions',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      // Updated unclustered point layer
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'auctions',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': [
            'match',
            ['get', 'category'],
            'wiscosurp', sourceColors.wiscosurp,
            'hibid', sourceColors.hibid,
            'publicsurplus', sourceColors.publicsurplus,
            'gsa', sourceColors.gsa,
            'backes', sourceColors.backes,
            'bidspotter', sourceColors.bidspotter,
            'smc', sourceColors.smc,
            'heartland', sourceColors.heartland,
            'proxibid', sourceColors.proxibid,
            sourceColors.default
          ],
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': [
            'case',
            ['==', ['get', 'favorite'], 'Y'],
            '#ff0000',  // Red stroke for favorites
            '#ffffff'   // White stroke for non-favorites
          ]
        }
      });
    }

    // Event listeners
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      const clusterId = features[0].properties.cluster_id;
      const pointCount = features[0].properties.point_count;
      const clusterSource = map.getSource('auctions');

      clusterSource.getClusterLeaves(clusterId, pointCount, 0, (err, aFeatures) => {
        if (err) {
          console.error('Error getting cluster leaves:', err);
          return;
        }
        
        const clusterItems = aFeatures.map(f => f.properties);
        setClusterData(clusterItems);
        setSidebarVisible(true);
      });
    });

    map.on('click', 'unclustered-point', (e) => {
      const properties = e.features[0].properties;
      setClusterData([properties]);
      setSidebarVisible(true);
    });
  }, []);

  const initializeMap = useCallback(() => {
    if (mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: darkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11',
      center: [center.lng, center.lat],
      zoom: zoom,
      maxBounds: [
        [US_BOUNDS.west, US_BOUNDS.south], // Southwest coordinates
        [US_BOUNDS.east, US_BOUNDS.north]  // Northeast coordinates
      ],
      minZoom: 3,
      maxZoom: 15
    });

    mapRef.current.on('load', () => {
      setMapLoaded(true);
      setupMapLayers();
    });

    // Add navigation controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

  }, [darkMode, center, zoom, setupMapLayers]);

  const handleClusterClick = useCallback((e) => {
    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });
    
    if (features.length > 0) {
      const clusterId = features[0].properties.cluster_id;
      const source = mapRef.current.getSource('auctions');
      
      source.getClusterLeaves(clusterId, Infinity, 0, (err, leaves) => {
        if (err) return;
        const clusterItems = leaves.map(leaf => leaf.properties);
        onClusterClick(clusterItems);
      });
    }
  }, [onClusterClick]);

  const handleUnclusteredPointClick = useCallback((e) => {
    const properties = e.features[0].properties;
    setClusterData([properties]);
    setSidebarVisible(true);
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('click', 'clusters', handleClusterClick);
      mapRef.current.on('click', 'unclustered-point', handleUnclusteredPointClick);
    }
    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', 'clusters', handleClusterClick);
        mapRef.current.off('click', 'unclustered-point', handleUnclusteredPointClick);
      }
    };
  }, [handleClusterClick, handleUnclusteredPointClick]);

  const updateMapData = useCallback(async () => {
    if (!mapLoaded || !mapRef.current) return;

    const source = mapRef.current?.getSource('auctions');
    if (source) {
      const validFeatures = data
        .filter(item => {
          const lng = parseFloat(item.longitude);
          const lat = parseFloat(item.latitude);
          return !isNaN(lng) && !isNaN(lat) &&
                 lng >= US_BOUNDS.west && lng <= US_BOUNDS.east &&
                 lat >= US_BOUNDS.south && lat <= US_BOUNDS.north;
        })
        .map(item => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)]
          },
          properties: {
            ...item,
            table_name: item.table_name || getCategoryFromUrl(item.url),
            category: item.url ? getCategoryFromUrl(item.url) : 'default',
            favorite: item.favorite
          }
        }));

      source.setData({
        type: 'FeatureCollection',
        features: validFeatures
      });

      if (validFeatures.length > 0) {
        const bounds = new mapboxgl.LngLatBounds();
        
        // First extend bounds with all valid points
        validFeatures.forEach(f => {
          const [lng, lat] = f.geometry.coordinates;
          if (lng >= US_BOUNDS.west && lng <= US_BOUNDS.east && 
              lat >= US_BOUNDS.south && lat <= US_BOUNDS.north) {
            bounds.extend(f.geometry.coordinates);
          }
        });

        if (!bounds.isEmpty()) {
          mapRef.current.fitBounds(bounds, {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
            maxZoom: DEFAULT_ZOOM,
            duration: 1500
          });
        } else {
          // If bounds are empty (no valid points), center on Cedar Rapids
          mapRef.current.flyTo({
            center: [CEDAR_RAPIDS_COORDS.lng, CEDAR_RAPIDS_COORDS.lat],
            zoom: DEFAULT_ZOOM,
            duration: 1500
          });
        }
      } else {
        // If no valid features, center on Cedar Rapids
        mapRef.current.flyTo({
          center: [CEDAR_RAPIDS_COORDS.lng, CEDAR_RAPIDS_COORDS.lat],
          zoom: DEFAULT_ZOOM,
          duration: 1500
        });
      }
    }
  }, [data, mapLoaded]);

  useEffect(() => {
    if (mapLoaded && data.length > 0) {
      updateMapData();
    }
  }, [updateTrigger, mapLoaded, data, updateMapData]);

  useEffect(() => {
    initializeMap();
    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initializeMap]);

  const getMap = () => {
    return mapRef.current;
  };

  React.useImperativeHandle(ref, () => ({
    getMap,
    clearMapData: () => {
      if (mapRef.current) {
        const source = mapRef.current.getSource('auctions');
        if (source) {
          source.setData({
            type: 'FeatureCollection',
            features: []
          });
        }
      }
    }
  }));

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex' }}>
      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
      {sidebarVisible && (
        <UnifiedSidebar
          data={clusterData}
          onClose={() => setSidebarVisible(false)}
          onFavorite={handleFavorite}
          onRowSelect={onRowSelect}
          selectedRows={selectedRows}
          darkMode={darkMode}
          onExport={() => {
            const dataToExport = selectedRows.length > 0
              ? clusterData.filter(item => selectedRows.includes(item.url))
              : clusterData;
            exportCSV(dataToExport, 'auction_data');
          }}
          onSearch={(searchTerm) => {
            // Add search functionality if needed
          }}
        />
      )}
    </div>
  );
});

export default MapComponent;
