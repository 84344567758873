import React, { useState, useMemo } from 'react';
import { 
  Box, 
  Paper, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton, 
  Typography, 
  ListItemSecondaryAction,
  Button,
  Tooltip,
  Checkbox
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';

const UnifiedSidebar = ({ 
  data, 
  isClusterView,
  onClose,
  onFavorite,
  onRowSelect,
  selectedRows = [], 
  onCategorySelect,
  darkMode,
  onExport,
  onSearch
}) => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  // Organize data by categories
  const categorizedData = useMemo(() => {
    if (!Array.isArray(data)) return {};
    return data.reduce((acc, item) => {
      const category = item.category || 'Uncategorized';
      acc[category] = acc[category] || [];
      acc[category].push(item);
      return acc;
    }, {});
  }, [data]);

  const handleItemClick = (category) => {
    setSelectedCategory(category);
    if (onCategorySelect) {
      onCategorySelect(category, categorizedData[category]);
    }
  };

  const handleBackClick = () => {
    setSelectedCategory(null);
    if (onCategorySelect) {
      onCategorySelect(null, null);
    }
  };

  const handleFavoriteClick = (e, item) => {
    e.stopPropagation();
    if (onFavorite) {
      onFavorite(item);
    }
  };

  const handleRowClick = (url) => {
    if (onRowSelect) {
      onRowSelect(url);
    }
  };

  const ActionButtons = () => (
    <Box sx={{ p: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
      <Tooltip title="Export selected">
        <Button
          size="small"
          startIcon={<GetAppIcon />}
          onClick={() => onExport && onExport(selectedCategory ? categorizedData[selectedCategory] : data)}
          variant="contained"
        >
          Export
        </Button>
      </Tooltip>
      <Tooltip title="Research similar">
        <Button
          size="small"
          startIcon={<SearchIcon />}
          onClick={() => onSearch && onSearch(selectedCategory || '')}
          variant="contained"
        >
          Research
        </Button>
      </Tooltip>
    </Box>
  );

  // Item list for selected category
  const ItemList = () => (
    <List>
      {selectedCategory && categorizedData[selectedCategory]?.map(item => (
        <ListItem
          key={item.url}
          onClick={() => handleRowClick(item.url)}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            ...(selectedRows.includes(item.url) && {
              backgroundColor: theme.palette.action.selected,
            }),
          }}
        >
          <Checkbox
            checked={selectedRows.includes(item.url)}
            onClick={(e) => {
              e.stopPropagation();
              handleRowClick(item.url);
            }}
            size="small"
          />
          <ListItemText
            primaryTypographyProps={{ component: 'div' }}
            primary={
              <a 
                href={item.url} 
                target="_blank" 
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                style={{ 
                  color: theme.palette.primary.main,
                  textDecoration: 'none'
                }}
              >
                {item.item_name}
              </a>
            }
            secondaryTypographyProps={{ component: 'div' }}
            secondary={
              <>
                <Typography component="div" variant="body2" color="text.secondary">
                  Location: {item.location}
                </Typography>
                <Typography component="div" variant="body2" color="text.secondary">
                  Time Left: {item.time_left}
                </Typography>
                <Typography component="div" variant="body2" color="text.secondary">
                  Current Bid: {item.current_bid}
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            <AnimatedFavoriteIcon
              isFavorite={item.favorite === 'Y'}
              onClick={(e) => handleFavoriteClick(e, item)}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const AnimatedFavoriteIcon = ({ isFavorite, onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        '& svg': {
          transition: 'all 0.3s ease',
          transform: 'scale(1)',
        },
        '&:hover svg': {
          transform: 'scale(1.2)',
        },
        '&:active svg': {
          transform: 'scale(0.8)',
        }
      }}
    >
      {isFavorite ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
    </IconButton>
  );

  // Modify title based on view type
  const getTitle = () => {
    if (isClusterView) return 'Cluster Items';
    if (selectedCategory) return selectedCategory;
    return 'Categories';
  };

  // Add cluster item view component
  const ClusterItemView = () => (
    <List>
      {data.map((item) => (
        <ListItem
          key={item.id || item.url}
          onClick={() => onRowSelect(item)}
          sx={{ 
            cursor: 'pointer',
            backgroundColor: selectedRows.includes(item.url) ? 
              (darkMode ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.04)') : 
              'transparent',
            '&:hover': {
              backgroundColor: darkMode ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.08)',
            },
          }}
        >
          <ListItemText
            primary={item.item_name || 'Unnamed Item'}
            secondary={
              <>
                <Typography variant="body2" color="text.secondary">
                  Location: {item.location || 'Unknown'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Time Left: {item.time_left || 'N/A'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Current Bid: {item.current_bid || 'N/A'}
                </Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton 
              edge="end" 
              onClick={(e) => {
                e.stopPropagation();
                onFavorite(item);
              }}
              size="small"
            >
              {item.favorite === 'Y' ? 
                <FavoriteIcon color="error" /> : 
                <FavoriteBorderIcon />
              }
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Paper 
      elevation={3}
      sx={{ 
        width: 400,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: theme.zIndex.drawer,
        borderRadius: 0,
        transition: 'var(--slide-animation)',
        transform: isOpen ? 'translateX(0)' : 'var(--slide-transform)',
      }}
    >
      <Box 
        sx={{ 
          p: 2, 
          display: 'flex', 
          alignItems: 'center',
          backgroundColor: '#0D7A3E',
          color: '#ffffff',
          flexShrink: 0
        }}
      >
        {selectedCategory && (
          <IconButton onClick={handleBackClick} size="small" sx={{ color: '#ffffff' }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography 
          variant="h6" 
          sx={{ flexGrow: 1, ml: selectedCategory ? 1 : 0 }}
        >
          {getTitle()}
        </Typography>
        <IconButton 
          onClick={() => {
            setIsOpen(false);
            setTimeout(onClose, 300); // Wait for animation to complete
          }} 
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ flexShrink: 0 }}>
        <ActionButtons />
      </Box>

      <Box sx={{ 
        flexGrow: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.background.paper,
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.divider,
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: theme.palette.action.hover,
        }
      }}>
        {isClusterView ? (
          <ClusterItemView />
        ) : (
          selectedCategory ? <ItemList /> : (
            <List>
              {Object.entries(categorizedData).map(([category, items]) => (
                <ListItem
                  key={category}
                  onClick={() => handleItemClick(category)}
                  sx={{ 
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: darkMode ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.04)',
                    },
                  }}
                >
                  <ListItemText
                    primary={`${category} (${items.length})`}
                  />
                </ListItem>
              ))}
            </List>
          )
        )}
      </Box>
    </Paper>
  );
};

export default UnifiedSidebar;