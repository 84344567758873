import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CategorizedCounts = ({ categorizedData, onCategoryClick }) => {
  const theme = useTheme();
  
  return (
    <Box 
      className="categorized-counts" 
      sx={{ 
        width: '300px', 
        padding: '20px',
        backgroundImage: theme.gradients.background,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Categorized Counts
      </Typography>
      <List>
        {Object.entries(categorizedData).map(([category, items]) => (
          <ListItem 
            key={category} 
            component="div"
            onClick={() => onCategoryClick(category)}
            sx={{ 
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <ListItemText 
              primary={`${category}: ${items.length}`} 
              sx={{
                '& .MuiListItemText-primary': {
                  fontWeight: 'bold',
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default CategorizedCounts;