/**
 * Endpoints for different auction sources
 * @returns {Object} - Object with auction sources as keys and their respective URLs as values
 */
const endpoints = {
  'Ending Today': 'http://hashbrowns/bin/ending-today.php',
  'GovDeals': 'http://hashbrowns/bin/govdeals-locations.php',
  'Wisconsin Surplus': 'http://hashbrowns/bin/wisco-locations.php',
  'HiBid': 'http://hashbrowns/bin/hibid-locations.php',
  'Public Surplus': 'http://hashbrowns/bin/publicsurplus-locations.php',
  'GSA': 'http://hashbrowns/bin/gsa-locations.php',
  'Backes': 'http://hashbrowns/bin/backes-locations.php',
  'Bidspotter': 'http://hashbrowns/bin/bidspotter-locations.php',
  'SMC': 'http://hashbrowns/bin/smc-locations.php',
  'Heartland': 'http://hashbrowns/bin/heartland-locations.php',
  'Proxibid': 'http://hashbrowns/bin/proxibid-locations.php', 
};

/**
 * Mapping of auction sources to their respective database table names
 * @returns {Object} - Object with auction sources as keys and their respective database table names as values
 */
const tableNames = {
  'Ending Today': 'ending_today',
  'GovDeals': 'govdeals',
  'Wisconsin Surplus': 'wiscosurp',
  'HiBid': 'hibid',
  'Public Surplus': 'publicsurplus',
  'GSA': 'gsa',
  'Backes': 'backes',
  'Bidspotter': 'bidspotter',
  'SMC': 'smc',
  'Heartland': 'heartland',
  'Proxibid': 'proxibid'
};

/**
 * Determines the category of an item based on its URL
 * @param {string} url - The URL of the auction item
 * @returns {string} - The determined category or 'unknown' if not found
 */
const getCategoryFromUrl = (url) => {
  if (url && typeof url === 'string') {
    const category = Object.values(tableNames).find(key => url.includes(key));
    if (category) return category;
  }
  return 'unknown';
};

/**
 * Groups an array of items by their category
 * @param {Array} items - Array of auction items
 * @returns {Object} - Object with categories as keys and arrays of items as values
 */
const groupItemsByCategory = (items) => {
  return items.reduce((acc, item) => {
    const category = item.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});
};

// Export all functions and configurations
module.exports = {
  endpoints,
  tableNames,
  getCategoryFromUrl,
  groupItemsByCategory
};