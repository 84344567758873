const categories = {
  'Fleet Maintenance and Vehicles': [
    'ford', 'chevrolet', 'chevy', 'dodge', 'freightliner', 'toyota', 'honda', 'nissan',
    'truck', 'van', 'suv', 'sedan', 'vehicle', 'ambulance', 'bus', 'trailer', 'forklift',
    'tractor', 'plow', 'dump truck', 'fire truck', 'police', 'interceptor', 'fleet',
    'transmission', 'engine', 'automotive', 'spreader', 'snowplow', 'snow plow'
  ],

  'Industrial and Commercial Equipment': [
    'cnc', 'lathe', 'drill press', 'compressor', 'generator', 'hvac', 'boiler',
    'industrial', 'commercial', 'manufacturing', 'welding', 'welder', 'plasma cutter',
    'woodworking', 'metalworking', 'machinery', 'excavator', 'loader', 'backhoe',
    
    'commercial kitchen', 'food service', 'refrigerator', 'freezer', 'oven', 'stove',
    'dishwasher', 'mixer', 'hobart', 'food prep', 'cooler', 'warming cabinet',
    'ice machine', 'griddle'
  ],

  'Office Equipment and Furniture': [
    'desk', 'chair', 'table', 'cabinet', 'file cabinet', 'bookshelf', 'cubicle',
    'workstation', 'credenza', 'conference', 'office furniture',
    
    'copier', 'printer', 'scanner', 'shredder', 'projector', 'whiteboard',
    'phone system', 'office equipment'
  ],

  'MRO (Maintenance, Repair, and Operations)': [
    'tools', 'power tools', 'hand tools', 'ladder', 'scaffolding', 'cart',
    'dolly', 'pressure washer', 'vacuum', 'cleaning equipment',
    
    'parts', 'supplies', 'hardware', 'fasteners', 'filters', 'belts',
    'maintenance', 'repair', 'janitorial', 'safety equipment'
  ],

  'Electronics and Technology': [
    'computer', 'laptop', 'desktop', 'server', 'monitor', 'keyboard', 'mouse',
    'ipad', 'tablet', 'apple', 'dell', 'hp', 'lenovo',
    
    'tv', 'television', 'audio', 'video', 'camera', 'security system',
    'surveillance', 'network', 'wireless'
  ],

  'Real Estate': [
    'property', 'land', 'lot', 'house', 'building', 'apartment', 'condo',
    'commercial property', 'residential', 'acre', 'sq ft', 'square foot',
    'warehouse', 'storage', 'garage', 'parking'
  ],

  'Medical and Laboratory': [
    'medical', 'laboratory', 'dental', 'hospital', 'exam', 'surgical',
    'healthcare', 'diagnostic', 'treatment', 'patient', 'clinical'
  ]
};

const categorizeItems = (itemData) => {
  return itemData.map(item => {
    const lowercaseItemName = item.item_name.toLowerCase();
    
    for (const [category, keywords] of Object.entries(categories)) {
      if (keywords.some(keyword => lowercaseItemName.includes(keyword.toLowerCase()))) {
        return { ...item, category };
      }
    }
 
    // Additional checks for specific patterns
    if (/\d{4}.*(?:ford|chevrolet|dodge|freightliner)/i.test(lowercaseItemName)) {
      return { ...item, category: 'Fleet Maintenance and Vehicles' };
    }
    
    if (/(?:metal|wood(?:en)?)\s+(?:desk|table|cabinet)/i.test(lowercaseItemName)) {
      return { ...item, category: 'Office Equipment and Furniture' };
    }
    
    return { ...item, category: 'Uncategorized' };
  });
};

module.exports = { categorizeItems, categories };